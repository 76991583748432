import React, { useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import useInView from "react-cool-inview";

import Layout from "../components/layout";
import SEO from "../components/seo";
import CtaButton from "../components/ctaButton";

const MenuBar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100px;
  background-color: #2d3445;
  @media (max-width: 670px) {
    display: none;
  }
`;

const Container = styled.div`
  height: calc(100vh - 635px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 25px;
  min-height: 370px;
`;

const TextContent = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Title = styled(motion.h1)`
  font-weight: 700;
  font-size: 62px;
  line-height: 62px;
  letter-spacing: -1px;
  color: ${(props) => props.color};
  font-family: "Poppins";
  margin-bottom: 15px;
  z-index: 2;
  @media (max-width: 1080px) {
    font-size: 52px;
  }
  @media (max-width: 768px) {
    font-size: 44px;
    line-height: 46px;
  }
`;

const Paragraph = styled(motion.div)`
  font-weight: 500;
  font-size: 17px;
  line-height: 28px;
  color: ${(props) => props.color};
  margin-top: 30px;
  margin-bottom: 30px;
  max-width: 65%;
  z-index: 2;
  @media (max-width: 1490px) {
    max-width: 600px;
  }
  @media (max-width: 1230px) {
    max-width: 500px;
  }
  @media (max-width: 1080px) {
    max-width: 400px;
  }
  @media (max-width: 950px) {
    max-width: 75%;
  }
  @media (max-width: 768px) {
    max-width: 100%;
    width: 100%;
    margin-top: 0px;
    margin-bottom: 0px;
  }
`;

const CtaContainer = styled(motion.div)``;

const TitleAnim = {
  hidden: {
    opacity: 0,
    y: 20,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.2,
    },
  },
};

const SubtitleAnim = {
  hidden: {
    opacity: 0,
    y: 20,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.3,
    },
  },
};

const CtaAnim = {
  hidden: {
    opacity: 0,
    y: 20,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.4,
    },
  },
};

function NotFoundPage({}) {
  const [isInView, setIsInView] = useState(true);
  const { ref, inView } = useInView({
    threshold: 0.25,
    onEnter: ({ scrollDirection, entry, observe, unobserve }) => {
      setIsInView(true);
    },
  });
  return (
    <Layout>
      <SEO title="404: Not found" />
      <MenuBar />
      <Container ref={ref}>
        <TextContent>
          <Title
            animate={isInView ? "visible" : "hidden"}
            variants={TitleAnim}
            initial="hidden"
          >
            404: Page Not Found.
          </Title>
          <Paragraph
            animate={isInView ? "visible" : "hidden"}
            variants={SubtitleAnim}
            initial="hidden"
          >
            We're not looking to point fingers here, but somebody's messed up.
            This page, just like Yosuke Ideguchi, never turned up.
          </Paragraph>
          <CtaContainer
            animate={isInView ? "visible" : "hidden"}
            variants={CtaAnim}
            initial="hidden"
          >
            <CtaButton
              text={"Go Home"}
              link={"/"}
              backgroundColor={"#fff"}
              backgroundColorHover={"#fff"}
              color={"#2D3445"}
            />
          </CtaContainer>
        </TextContent>
      </Container>
    </Layout>
  );
}

export default NotFoundPage;
